@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(assets/icons/css/flag-icon.css);
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

@layer base {
  html, body {
    overflow: auto !important;
    color: #475f7b;
    font-family: "Fira Sans", system-ui, sans-serif !important;
    font-size: inherit !important;
  }

  .num {
    font-family: "Fira Sans", system-ui, sans-serif !important;
  }

  .analytics-num {
    font-family: "Lato", cursive !important;
  }
  .iconShadow {
    filter: drop-shadow(4px 4px 1px rgba(0, 0, 0, 0.3));
  }

  .iconShadow-alt {
    filter: drop-shadow(-2px 2px 1px rgba(0, 0, 0, 0.3));
  }
    .rs-btn-default,
    .rs-picker-toggle-textbox {
      background-color: transparent !important;
    }

    
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: #388951 !important;
}

/* .MuiPickersDay-current, */
.MuiButton-textPrimary {
  color: #388951 !important;
}

.MuiDataGrid-menu {
  z-index: 3000 !important;
}

.message-container::-webkit-scrollbar {
  display: none;
}

.EmojiPickerReact button.epr-emoji .epr-emoji-img {
  width: 35px
}
.EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label {
  font-size: 14px;
  background-color: transparent;
}
.EmojiPickerReact {
  background-color: transparent;
}

.rs-btn-default.rs-picker-toggle,
.rs-picker-toggle-textbox {
  background-color: transparent !important;
  outline: none !important;
  /* border-color: none !important; */
}
